import { useState, useEffect } from "react";
import {
  MdOutlineLightMode,
  MdOutlineNightlight,
  MdMoney,
  MdOutlineShoppingCart,
  MdPayments,
  MdBarChart,
  MdOutlineSendToMobile,
} from "react-icons/md";
import {
  TbSettingsAutomation,
  TbLink,
  TbShare,
  TbRadar2,
} from "react-icons/tb";
import { SiMicrosoftexcel } from "react-icons/si";
import { RiRefund2Fill } from "react-icons/ri";

import { useNavigate } from "react-router-dom";
import BurgerIcon from "./BurgerIcon";
import NavUser from "./NavUser";
import UserMini from "./UserMini";

const Layout = ({ signOut, children, userDetail }) => {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [selectProducts, setSelectProducts] = useState(false);
  const [selectCompany, setSelectCompany] = useState(false);
  const [selectSupport, setSelectSupport] = useState(false);
  const [burgerClick, setBurger] = useState(false);
  const [userClick, setUserClick] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
    if (theme) {
      setDarkMode(true);
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      window.localStorage.setItem("theme", "dark");
    } else {
      window.localStorage.removeItem("theme");
    }
  }, [darkMode]);

  const closeDrop = () => {
    if (selectProducts) {
      setSelectProducts(false);
    }
    if (selectCompany) {
      setSelectCompany(false);
    }
    if (selectSupport) {
      setSelectSupport(false);
    }
    if (userClick) {
      setUserClick(false);
    }
    if (activeIndex >= 0) {
      setActiveIndex(-1);
    }
  };

  return (
    <div className={`w-screen h-screen ${darkMode && "dark"}`}>
      <div
        className=" dark:text-white flex flex-col justify-between dark:bg-black w-full h-full"
        onClick={() => closeDrop()}
      >
        <header className="w-full shadow-lg shadow-yellow-500/30 dark:shadow-blue-400/20 font-mulish">
          <nav className="p-2 flex flex-row justify-between bg-gradient-to-r items-center w-full from-teal-600/50 via-green-400/50 to-yellow-2000/40 dark:from-transparent dark:via-slate-700/20 dark:to-slate-700/30">
            <div onClick={() => navigate("/")} className="flex items-center">
              <img
                src="/logo192.png"
                className="w-20 rounded-full inline-block h-full object-contain"
                alt="logo"
              />
              <h1 className="2xl:block hidden font-bold text-yellow-500/50 font-mulish dark:text-blue-500 lg:text-3xl text-md">
                Contoh Payment Gateway
              </h1>
            </div>
            <div className="px-3 flex gap-1 items-center md:w-full">
              <div className="md:flex gap-2 justify-around font-sans text-yellow-500/80 dark:text-white pb-1 px-5 hidden text-2xl w-full">
                {userDetail ? (
                  <NavUser
                    closeDrop={closeDrop}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                  />
                ) : (
                  <>
                    <div
                      className={navNode}
                      onClick={() => setSelectProducts(!selectProducts)}
                    >
                      <span
                        aria-hidden="true"
                        className={navSpanBefore(selectProducts)}
                      >
                        [
                      </span>
                      Produk
                      <span
                        aria-hidden="true"
                        className={navSpanAfter(selectProducts)}
                      >
                        ]
                      </span>
                      <Products _props={selectProducts} />
                    </div>
                    <a className={navNode}>
                      <span aria-hidden="true" className={navSpanBefore(false)}>
                        [
                      </span>
                      Harga
                      <span aria-hidden="true" className={navSpanAfter(false)}>
                        ]
                      </span>
                    </a>
                    <div
                      className={navNode}
                      onClick={() => setSelectCompany(!selectCompany)}
                    >
                      <span
                        aria-hidden="true"
                        className={navSpanBefore(selectCompany)}
                      >
                        [
                      </span>
                      Perusahaan
                      <span
                        aria-hidden="true"
                        className={navSpanAfter(selectCompany)}
                      >
                        ]
                      </span>
                      <Company _props={selectCompany} />
                    </div>
                    <a
                      className={navNode}
                      onClick={() => setSelectSupport(!selectSupport)}
                    >
                      <span
                        aria-hidden="true"
                        className={navSpanBefore(selectSupport)}
                      >
                        [
                      </span>
                      Bantuan
                      <span
                        aria-hidden="true"
                        className={navSpanAfter(selectSupport)}
                      >
                        ]
                      </span>
                      <Support _props={selectSupport} />
                    </a>
                  </>
                )}
              </div>

              <div
                className="md:w-16 md:h-7 w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer"
                onClick={() => {
                  setDarkMode(!darkMode);
                }}
              >
                {/* Switch */}
                <div
                  className={`bg-white md:w-6 md:h-6 h-5 w-5 transition ease-in-out duration-75 rounded-full shadow-md ${
                    darkMode && "transform translate-x-5"
                  }`}
                >
                  {darkMode ? (
                    <MdOutlineNightlight className="w-full h-full bg-slate-600 rounded-full" />
                  ) : (
                    <MdOutlineLightMode className="w-full h-full bg-yellow-400 rounded-full text-red-600" />
                  )}
                </div>
              </div>
              {userDetail ? (
                <UserMini
                  userClick={userClick}
                  setUserClick={setUserClick}
                  name={userDetail.first_name}
                  avatar={userDetail.avatar_url}
                  lastName={userDetail.last_name}
                  signOut={signOut}
                />
              ) : (
                <div className="md:hidden">
                  <BurgerIcon setIsOpen={setBurger} isOpen={burgerClick} />
                </div>
              )}
            </div>
          </nav>
        </header>
        <main className="flex h-full overflow-hidden">{children}</main>
        <footer className="w-full py-3 px-4 bg-gradient-to-r from-teal-700/60 via-green-400/60 to-yellow-200/60 dark:from-slate-700/20 dark:via-slate-600/30">
          {/* Container */}
          <h2 className="sr-only">Footer</h2>
          <div className="mx-auto w-full flex flex-col items-center text-gray-200">
            <div className="w-full flex flex-col md:flex-row flex-wrap justify-between items-center">
              {/* :SOCIAL NETWORKS */}
              <div className="pt-2 px-2 flex">
                {/* Facebook */}
                <a href="#link" className="text-gray-200">
                  <span className="sr-only">Facebook</span>
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </a>
                {/* Twitter */}
                <a href="#link" className="ml-3 text-white">
                  <span className="sr-only">Twitter</span>
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                  </svg>
                </a>
                {/* Instagram */}
                <a href="#link" className="ml-3 text-white">
                  <span className="sr-only">Instagram</span>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </a>
                {/* Linkedin */}
                <a
                  href="https://id.linkedin.com/in/iqbal-abdurachman-632b5433"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-3 text-white"
                >
                  <span className="sr-only">Linkedin</span>
                  <svg
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="none"
                      d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                    ></path>
                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                  </svg>
                </a>
                {/* GitHub */}
                <a href="#link" className="ml-3 text-gray-50">
                  <span className="sr-only">GitHub</span>
                  <svg
                    fill="currentColor"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
              {/* :CONTACT */}
              <p className="py-1 text-sm text-white text-center">
                &copy;2022, Iqbal Abdurachman All Rights Reserved.
              </p>
              <div className="px-2 flex flex-col">
                {/* Email */}
                <p className="flex text-xs text-white font-medium tracking-wide">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-1 h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                  <a href="#email">abdurachman.iqbal@icloud.com</a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <div
        className={`${
          burgerClick ? "transform h-full opacity-100" : "h-0 opacity-0"
        } text-black font-mulish duration-500 ease-in-out font-semibold dark:text-white flex-col justify-start items-start absolute top-24 left-0 w-full backdrop-blur-lg rounded-sm bg-white/30`}
      >
        {burgerClick && (
          <>
            <div className="w-full my-5">
              <h1 className="font-sans font-bold text-center text-2xl dark:text-white text-amber-700">
                Contoh Payment Gateway
              </h1>
            </div>
            <MobileMenuProduct />
            <div className="flex mt-4 px-4 w-full py-2">
              <ul className="py-1 flex flex-wrap border justify-center text-md dark:text-gray-200">
                {companyArr.map((comp, i) => (
                  <li key={i}>
                    <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                      {comp}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Layout;

const MobileMenuProduct = () => {
  return (
    <div className="flex flex-wrap w-full">
      <div id="payment" className="flex flex-col w-1/2 gap-3 justify-start">
        <h1 className="text-xl dark:text-white ml-3 font-semibold">Payments</h1>
        <ul className="py-1 text-md dark:text-gray-200">
          {payNav.map((comp, i) => (
            <li key={i}>
              <div className="flex gap-3 py-1 px-2 justify-start items-center">
                <div className="dark:bg-green-700 bg-orange-500 text-white p-2 text-3xl rounded-full">
                  {comp.icon}
                </div>
                <div className="flex flex-col justify-start items-start">
                  <h1 className="py-2 px-1 text-md text-start rounded hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    {comp.title}
                  </h1>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div
        id="risk"
        className="flex flex-col border-none w-1/2 gap-3 justify-start"
      >
        <h1 className="text-xl dark:text-white ml-3 font-semibold">
          Business Operation
        </h1>
        <ul className="py-1 text-md dark:text-gray-200">
          {riskNav.map((comp, i) => (
            <li key={i}>
              <div className="flex gap-3 px-2 justify-start items-center">
                <div className="dark:bg-red-700 bg-blue-500 text-white p-2 text-3xl rounded-full">
                  {comp.icon}
                </div>
                <div className="flex flex-col justify-start items-start">
                  <h1 className="py-2 px-1 text-md text-start rounded hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    {comp.title}
                  </h1>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div
        id="platform"
        className="flex flex-col border-none gap-3 justify-start"
      >
        <h1 className="text-xl mt-2 dark:text-white ml-3 font-semibold">
          For small medium enterprise
        </h1>
        <ul className=" text-md dark:text-gray-200">
          {platNav.map((comp, i) => (
            <li key={i}>
              <div className="flex py-1 gap-3 px-2 justify-start items-center">
                <div className="dark:bg-indigo-700 bg-yellow-500 text-white p-2 text-3xl rounded-full">
                  {comp.icon}
                </div>
                <div className="flex flex-col justify-start items-start">
                  <h1 className="py-2 px-1 text-md text-start rounded hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    {comp.title}
                  </h1>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const navNode =
  "group appearance-none bg-transparent border-none cursor-pointer text-xl tracking-wide antialiased focus:outline-none";
const navSpanAfter = (props) =>
  `inline-block ${
    !props && "opacity-0 transform -translate-x-5"
  } ml-2.5 transition-all duration-300   group-hover:opacity-100 group-hover:translate-x-0`;
const navSpanBefore = (props) =>
  `inline-block ${
    !props && "opacity-0 transform translate-x-5"
  } mr-2.5 transition-all duration-300 group-hover:opacity-100 group-hover:translate-x-0`;

const companyArr = [
  "Tentang Kami",
  "Pelanggan Kami",
  "Blog",
  "Events",
  "Mitra Kami",
  "Lisensi Kami",
  "Asset and Branding",
  "Karir",
];

const supArr = ["FAQS", "Dokumentasi", "Referensi API"];

const riskNav = [
  {
    icon: <TbRadar2 />,
    title: "Manajemen risiko",
    content: "Pencegahan dan pendeteksi penipuan",
  },
  {
    icon: <MdPayments />,
    title: "Platform Payments",
    content: "Sistem untuk marketplace",
  },
  {
    icon: <MdBarChart />,
    title: "Pendanaan",
    content: "Pembiyaan fleksibel untuk bisnis",
  },
];
const platNav = [
  {
    icon: <MdOutlineSendToMobile />,
    title: "Aplikasi Mobile",
    content: "Kirim tagihan dari smartphone",
  },
  {
    icon: <TbLink />,
    title: "Payment link",
    content: "Terima pembayaran dengan membagikan tautan",
  },
  {
    icon: <TbShare />,
    title: "Integrasi",
    content: "Integrasi dengan toko eCommerce",
  },
];

const payNav = [
  {
    icon: <MdMoney />,
    title: "Pembayaran Online",
    content: "Aktifkan 20+ metode pembayaran untuk bisnis",
  },
  {
    icon: <MdOutlineShoppingCart />,
    title: "Checkout",
    content: "Laman pembayaran yang teroptimasi",
  },
  {
    icon: <TbSettingsAutomation />,
    title: "Transfer otomatis",
    content: "Kirim uang dengan trigger API",
  },
  {
    icon: <SiMicrosoftexcel />,
    title: "Transfer masal",
    content: "Kirim uang dengan file excel",
  },
  {
    icon: <RiRefund2Fill />,
    title: "Kirim refund",
    content: "Proses refund tanpa rekening tujuan",
  },
];

const Company = ({ _props }) => {
  return (
    <div
      className={`z-10 absolute ${
        !_props && "hidden"
      } w-44 bg-blue-50/90 rounded divide-y divide-gray-100 mt-5 shadow dark:bg-black/70`}
    >
      <ul className="py-1 text-md dark:text-gray-200">
        {companyArr.map((comp, i) => (
          <li key={i}>
            <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              {comp}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Products = ({ _props }) => {
  return (
    <div
      className={`z-10 absolute ${
        _props ? "transform opacity-100 h-fit" : "opacity-0 h-0"
      } w-[80rem] flex bg-blue-50/90 text-red-500 ease-in-out duration-700 delay-150 rounded-lg gap-5 divide-y px-4 py-2 divide-gray-100 mt-5 right-40 shadow dark:bg-black/80`}
    >
      {_props && (
        <>
          <div id="payment" className="flex flex-col gap-3 justify-start">
            <h1 className="text-xl dark:text-white ml-3 font-semibold">
              Payments
            </h1>
            <ul className="py-1 text-md dark:text-gray-200">
              {payNav.map((comp, i) => (
                <li key={i}>
                  <div className="flex gap-3 px-2 justify-start items-center">
                    <div className="dark:bg-green-700 bg-orange-500 text-white p-2 text-3xl rounded-full">
                      {comp.icon}
                    </div>
                    <div className="flex flex-col justify-start items-start">
                      <h1 className="py-2 px-1 text-md text-start rounded hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        {comp.title}
                      </h1>
                      <p className="text-sm px-1">{comp.content}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            id="risk"
            className="flex flex-col border-none gap-3 justify-start"
          >
            <h1 className="text-xl dark:text-white ml-3 font-semibold">
              Business Operation
            </h1>
            <ul className="py-1 text-md dark:text-gray-200">
              {riskNav.map((comp, i) => (
                <li key={i}>
                  <div className="flex gap-3 px-2 justify-start items-center">
                    <div className="dark:bg-red-700 bg-blue-500 text-white p-2 text-3xl rounded-full">
                      {comp.icon}
                    </div>
                    <div className="flex flex-col justify-start items-start">
                      <h1 className="py-2 px-1 text-md text-start rounded hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        {comp.title}
                      </h1>
                      <p className="text-sm px-1">{comp.content}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            id="platform"
            className="flex flex-col border-none gap-3 justify-start"
          >
            <h1 className="text-xl dark:text-white ml-3 font-semibold">
              For small medium enterprise
            </h1>
            <ul className="py-1 text-md dark:text-gray-200">
              {platNav.map((comp, i) => (
                <li key={i}>
                  <div className="flex gap-3 px-2 justify-start items-center">
                    <div className="dark:bg-indigo-700 bg-yellow-500 text-white p-2 text-3xl rounded-full">
                      {comp.icon}
                    </div>
                    <div className="flex flex-col justify-start items-start">
                      <h1 className="py-2 px-1 text-md text-start rounded hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        {comp.title}
                      </h1>
                      <p className="text-sm px-1">{comp.content}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

const Support = ({ _props }) => {
  return (
    <div
      className={`z-10 absolute ${
        !_props && "hidden"
      } w-44 bg-blue-50/90 rounded divide-y divide-gray-100 mt-5 shadow dark:bg-black/70`}
    >
      <ul className="py-1 text-md dark:text-gray-200">
        {supArr.map((comp, i) => (
          <li key={i}>
            <span className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              {comp}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
