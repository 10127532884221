// import { useEffect, useState } from "react";

import { MdNotifications } from "react-icons/md";
import {
  FaGlobeAmericas,
  FaRegQuestionCircle,
  FaAngleDown,
} from "react-icons/fa";

const NavUser = ({ activeIndex, setActiveIndex }) => {
  const handle = (props) => {
    setActiveIndex(props);
  };

  return (
    <>
      {navItem.map((item, i) => (
        <div key={i}>
          <div
            onClick={() => handle(i)}
            className="flex gap-2 bg-inherit cursor-pointer px-3 py-1 items-center"
          >
            <div className="">{item.icon}</div>
            <div className="text-md relative flex items-center justify-center gap-[3px]">
              {item.title}
              <FaAngleDown />
              {activeIndex === i && item.comp}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default NavUser;

const notifItem = [
  "Notifikasi 1",
  "Notifikasi 2",
  "Notifikasi 3",
  "Notifikasi 4",
];
const notifBantuan = ["Docs", "API", "Berikan Saran Anda"];
const notifBahasa = ["Bahasa Indonesia", "Bahasa Inggris"];

const navItem = [
  {
    icon: <MdNotifications />,
    title: "Notifikasi",
    comp: (
      <div className="absolute left-0 bg-black/30 backdrop-blur-md rounded w-36 top-10 flex flex-col">
        {notifItem.map((elem, i) => (
          <div key={i} className="border-b-2 hover:bg-gray-900 rounded text-lg">
            <h1 className="py-1 px-2 text-start w-full">{elem}</h1>
          </div>
        ))}
      </div>
    ),
  },
  {
    icon: <FaRegQuestionCircle />,
    title: "Bantuan",
    comp: (
      <div className="absolute p-1 left-0 bg-black/30 backdrop-blur-md w-44 rounded top-10 flex flex-col">
        {notifBantuan.map((elem, i) => (
          <div key={i} className="border-b-2 hover:bg-gray-900 rounded text-lg">
            <h1 className="py-1 px-2 text-start w-full">{elem}</h1>
          </div>
        ))}
      </div>
    ),
  },
  {
    icon: <FaGlobeAmericas />,
    title: "Bahasa",
    comp: (
      <div className="absolute left-0 bg-black/30 backdrop-blur-md w-56 rounded top-10 flex flex-col">
        {notifBahasa.map((elem, i) => (
          <div key={i} className="border-b-2 hover:bg-gray-900 rounded text-lg">
            <h1 className="py-1 px-2 text-start w-full">{elem}</h1>
          </div>
        ))}
      </div>
    ),
  },
];
