import { useEffect, useState } from "react";
import {
  ethLogo,
  polygonLogo,
  avalancheLogo,
  binanceLogo,
  bitcoinLogo,
  solanaLogo,
  cardanoLogo,
} from "../components/Web3Logo";

import {
  miniAvalanche,
  miniBinance,
  miniBitcoin,
  miniPol,
  miniSol,
  miniEth,
  miniCardano,
} from "../components/Web3LogoMini";

import ModalWallet from "../components/ModalWallet";
import ModalToken from "../components/ModalToken";

import { ethers } from "ethers";

import { supabase } from "../client";

const Crypto = ({
  detail,
  formater,
  wallet,
  auth,
  logOutWallet,
  provider,
  setWallet,
  setDetail,
}) => {
  const [prices, setPrices] = useState([]);
  const [modalAuth, setModalAuth] = useState(false);
  // const [sec, setSec] = useState("");
  const [tokenAmount, setTokenAmount] = useState(0);
  const [inputBal, setInputBal] = useState("");
  const [passed, setPassed] = useState(false);
  const [reqSign, setReqSign] = useState(false);
  const [selectedChain, setSelectedChain] = useState(null);
  const [modalWithdraw, setModalWithdraw] = useState(null);

  async function fetchTicker() {
    const gecko = await ethers.utils.fetchJson(
      "https://api.coingecko.com/api/v3/simple/price?ids=cardano%2Cethereum%2Cmatic-network%2Csolana%2Cbinancecoin%2Cavalanche-2%2Cbitcoin&vs_currencies=idr"
    );
    for (const elem in gecko) {
      setPrices((old) => [
        ...old,
        {
          symbol: elem,
          priceJual:
            Number(gecko[elem].idr) + (Number(gecko[elem].idr) * 10) / 100,
          price: Number(gecko[elem].idr),
        },
      ]);
    }
  }

  const fetchBal = (index) => {
    if (index === 0) return wallet.eth_bal;
    if (index === 1) return wallet.matic_bal;
    if (index === 2) return wallet.cardano_bal;
    if (index === 3) return wallet.sol_bal;
    if (index === 4) return wallet.binance_bal;
    if (index === 5) return wallet.avax_bal;
    if (index === 6) return wallet.bitcoin_bal;
  };

  const returnPrice = (ticker) => {
    const coin = prices.find((elem) => elem.symbol === ticker);
    return coin.price;
  };
  const returnJual = (ticker) => {
    const coin = prices.find((elem) => elem.symbol === ticker);
    return coin.priceJual;
  };

  const handleSec = (e) => {
    e.preventDefault();
    const passValue = e.target.value;
    const hashValue = ethers.utils.solidityKeccak256(
      ["string"],
      [`${detail.email}${detail.first_name}${detail.last_name}${passValue}`]
    );
    if (hashValue === detail.challenge) {
      setPassed(true);
    } else {
      setPassed(false);
    }
  };

  useEffect(() => {
    fetchTicker();
  }, []);

  const handleAuth = async () => {
    setModalAuth(!modalAuth);
    await auth();
  };

  const updateSupabaseCrypto = async (chain, nominal, address) => {
    let result;
    const { data, error } = await supabase
      .from("wallet")
      .update({ [chain]: nominal })
      .match({ wallet_address: address });
    if (error) {
      console.log(error);
    }
    if (data) {
      result = data;
    }
    return result;
  };

  const updateBalance = async (id, jumlah) => {
    let result;
    const { data, error } = await supabase
      .from("profiles")
      .update({ balance: jumlah })
      .match({ id: id });
    if (error) {
      console.log(error);
    }
    if (data) {
      result = data;
    }
    return result;
  };

  const msg = (chains, nominal, tknAmount, name) => {
    const sym = chain.find((elem) => elem.tittle === chains);
    const sig = `name : ${name} \n \n withdraw amount : ${formater.format(
      nominal
    )} \n \n convert to : ${tknAmount} ${sym.symbol}`;
    return sig;
  };

  const withdrawFunc = async () => {
    setReqSign(true);
    if (inputBal === 0 || inputBal.length === 0) return;
    const signer = provider.getSigner();

    const sigMsg = msg(selectedChain, inputBal, tokenAmount, detail.first_name);

    const signature = await signer.signMessage(sigMsg);
    const addr = ethers.utils.verifyMessage(sigMsg, signature);
    const hasil = detail.balance - inputBal;

    const key = chain.find((elem) => elem.tittle === selectedChain);
    const bal = await updateBalance(detail.id, hasil);
    setDetail(bal[0]);
    const crypto = await updateSupabaseCrypto(key.balance, tokenAmount, addr);
    setWallet(crypto[0]);
    setReqSign(false);
    handleClose();
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    const jumlah = e.target.value;
    if (jumlah > detail.balance) {
      setInputBal(detail.balance);
    } else if (jumlah < 0) {
      setInputBal(0);
    } else {
      setInputBal(jumlah);
    }
  };

  useEffect(() => {
    if (!selectedChain) return;
    let mplier = Number(returnJual(selectedChain));
    const hasil = Number(inputBal) / mplier;
    setTokenAmount(hasil);
  }, [inputBal, selectedChain]);

  const clickWithdraw = (chains) => {
    setSelectedChain(chains);
    setModalWithdraw(!modalWithdraw);
  };

  const handleClose = () => {
    setTokenAmount(0);
    setInputBal("");
    setSelectedChain(null);
    setModalWithdraw(null);
  };

  const btnAuth = async () => {
    setModalAuth(!modalAuth);
  };

  return (
    <div className="flex gap-2 font-sans flex-col p-5">
      <div id="judul" className="w-full h-1/6 flex flex-col gap-2">
        <div className="animate-textShimmer font-mullish py-3 text-4xl font-bold bg-clip-text text-transparent bg-[linear-gradient(110deg,#e2e8f0,45%,#1e293b,55%,#e2e8f0)] bg-[length:250%_100%]">
          Crypto Wallet
        </div>
        <div className="flex flex-col md:flex-row gap-3 text-lg text-white/90 font-semibold md:text-md">
          <p className="">Jumlah Saldo :</p>
          <p className="animate-pulse">{formater.format(detail?.balance)}</p>
        </div>
        <div className="flex flex-col md:flex-row justify-between text-gray-400/90 dark:text-white font-semibold gap-2 items-center">
          <div className="flex flex-col w-full md:w-1/2 md:flex-row justify-center md:justify-start gap-2">
            <h1>Address wallet :</h1>
            {wallet?.wallet_address ? (
              <a
                className="md:truncate text-blue-600 underline md:w-1/3"
                target="_blank"
                href={`https://etherscan.io/address/${wallet.wallet_address}`}
                rel="noreferrer"
              >
                {wallet.wallet_address}
              </a>
            ) : (
              <p className="italic">please register your wallet</p>
            )}
          </div>
          <div className="flex flex-col gap-3 items-center md:flex-row">
            {!wallet ? (
              <>
                <h1 className="w-full">Sambungkan Wallet</h1>
                <button
                  onClick={() => btnAuth()}
                  className="px-3 py-1 hover:scale-110 active:scale-95 active:transform-none transform ease-in-out duration-100 text-white rounded-lg bg-gradient-to-r from-sky-800 via-sky-600 to-sky-400 shadow-sm shadow-blue-500/50"
                >
                  Autentikasi
                </button>
              </>
            ) : (
              <>
                <h1 className="w-full">Ganti Wallet</h1>
                <button
                  onClick={() => logOutWallet()}
                  className="px-3 py-1 hover:scale-110 active:scale-95 active:transform-none transform ease-in-out duration-100 text-white rounded-lg bg-gradient-to-r from-red-800 via-red-600 to-orange-400 shadow-sm shadow-blue-500/50"
                >
                  Ganti
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-full pt-8">
        <div className="flex flex-wrap w-full gap-5 justify-around">
          {chain.map((item, i) => (
            <div
              key={i}
              className="rounded-lg mb-10 w-full md:w-1/4 lg:w-1/59 justify-between flex flex-col shadow-md dark:shadow-slate-700 shadow-slate-600 py-3 px-5"
            >
              <div className="md:flex box-border flex-wrap hidden justify-center items-center h-full p-4 md:p-8">
                {item.icon}
              </div>
              <div className="md:hidden flex justify-center items-center object-fill overflow-hidden p-2 md:p-4">
                {item.miniIcon}
              </div>
              <div className="pb-2 flex gap-1">
                Saldo:
                {wallet ? (
                  <>
                    <p className="truncate">
                      {fetchBal(i)}
                      {"  "}
                    </p>
                    <p>{item.symbol}</p>
                  </>
                ) : (
                  <p className="italic animate-pulse">
                    Harap sambungkan wallet
                  </p>
                )}
              </div>
              {prices.length > 0 && (
                <p className="text-teal-600">
                  Beli : {formater.format(returnPrice(item.tittle))}
                </p>
              )}
              {prices.length > 0 && (
                <p className="text-rose-700">
                  Jual : {formater.format(returnJual(item.tittle))}
                </p>
              )}
              {/* <div className="flex justify-between gap-3 py-3"> */}
              {/* <button className="rounded-lg bg-gradient-to-r text-white active:scale-95 ease-in delay-50 from-teal-700 to-sky-500 shadow-md shadow-blue-400/90 duration-100 hover:scale-105 px-2 py-1">
                  Deposit
                </button> */}
              <button
                onClick={() => clickWithdraw(item.tittle)}
                disabled={!wallet}
                className={`rounded-lg text-white mt-2 px-2 py-1 ${
                  wallet
                    ? "bg-gradient-to-r active:scale-95 ease-in delay-50 from-red-600 to-rose-400/90 shadow-md duration-100 shadow-rose-300/90 hover:scale-105"
                    : "bg-gray-300/50 cursor-not-allowed"
                }`}
              >
                Withdraw
              </button>
              {/* </div> */}
            </div>
          ))}
        </div>
      </div>
      <ModalWallet
        show={modalAuth}
        setShow={setModalAuth}
        // sec={sec}
        // setSec={setSec}
        handleAuth={handleAuth}
        handleSec={handleSec}
        passed={passed}
      />
      <ModalToken
        formater={formater}
        tokenAmount={tokenAmount}
        handleClose={handleClose}
        show={modalWithdraw}
        change={onChangeInput}
        inputBal={inputBal}
        selectedChain={selectedChain}
        chain={chain}
        func={withdrawFunc}
        reqSign={reqSign}
      />
    </div>
  );
};

export default Crypto;

const chain = [
  {
    tittle: "ethereum",
    icon: ethLogo(),
    miniIcon: miniEth,
    symbol: "ETH",
    balance: "eth_bal",
  },
  {
    tittle: "matic-network",
    icon: polygonLogo(),
    miniIcon: miniPol,
    symbol: "MATIC",
    balance: "matic_bal",
  },
  {
    tittle: "cardano",
    icon: cardanoLogo(),
    miniIcon: miniCardano,
    symbol: "Cardano",
    balance: "cardano_bal",
  },
  {
    tittle: "solana",
    icon: solanaLogo(),
    miniIcon: miniSol,
    symbol: "SOL",
    balance: "sol_bal",
  },
  {
    tittle: "binancecoin",
    icon: binanceLogo(),
    miniIcon: miniBinance,
    symbol: "BNB",
    balance: "binance_bal",
  },
  {
    tittle: "avalanche-2",
    icon: avalancheLogo(),
    miniIcon: miniAvalanche,
    symbol: "AVAX",
    balance: "avax_bal",
  },
  {
    tittle: "bitcoin",
    icon: bitcoinLogo(),
    miniIcon: miniBitcoin,
    symbol: "BTC",
    balance: "bitcoin_bal",
  },
];
