import { useEffect, useState } from "react";
import { MdVpnKey } from "react-icons/md";

const Modal = ({
  passed,
  setShowModal,
  show,
  nominal,
  setNominal,
  balance,
  sec,
  setSec,
  withdraw,
}) => {
  const [focused, setFocused] = useState(null);
  const [initial, setInitial] = useState("hidden");

  useEffect(() => {
    if (show) {
      setInitial("roll-out-blurred-left");
    }
  }, [show]);

  const handleNominal = (e) => {
    const target = e.target.value;
    if (target < 0) {
      setNominal(0);
    } else if (target > balance) {
      setNominal(balance - 1000);
    } else {
      setNominal(target);
    }
  };

  const handlePass = (e) => {
    setSec(e.target.value);
  };

  return (
    <div
      className={`
    ${show ? "flex bounce-in-right" : initial}
    absolute top-1/3 w-full md:w-1/2 2xl:w-1/4 md:left-1/4 2xl:left-[50rem] left-1 px-12 py-5 bg-sky-900/10 dark:bg-white/20 backdrop-blur-lg rounded-lg flex-col`}
    >
      <h1 className="text-gray-200 font-sans font-semibold text-lg">Nominal</h1>
      <div className="my-5 mr-5 relative">
        {/* Label */}
        <label htmlFor="price" className="sr-only">
          Price
        </label>
        {/* Currency */}
        <span className="absolute inset-y-0 left-0 pl-1 inline-flex items-center text-lg text-yellow-500 font-semibold">
          Rp
        </span>
        {/* Input */}
        <input
          type="number"
          name="price"
          id="price"
          onChange={handleNominal}
          value={nominal}
          placeholder="0,00"
          className="form-input text-gray-500 font-sans pl-8 w-full border-0 border-b-2 border-gray-300 bg-white bg-opacity-80 focus:border-gray-300 focus:font-semibold focus:ring-0"
          onFocus={() => setFocused(8)}
          onBlur={() => setFocused(null)}
        />
        {/* Border bottom effect */}
        <span
          className={`absolute bottom-0 left-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-in transform ${
            focused === 8 ? "w-7/12" : "w-0"
          }`}
          aria-hidden="true"
        />{" "}
        {/* left side */}
        <span
          className={`absolute bottom-0 right-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-in transform ${
            focused === 8 ? "w-7/12" : "w-0"
          }`}
          aria-hidden="true"
        />{" "}
        {/* right side */}
      </div>
      <h1 className="text-gray-200 font-sans font-semibold text-lg">
        Kata Sandi
      </h1>
      <div className="my-5 mr-5 relative overflow-hidden">
        {/* Label */}
        <label htmlFor="password" className="sr-only">
          Password
        </label>
        {/* Icon */}
        <span className="absolute inset-y-0 left-0 pl-3 inline-flex items-center pointer-events-none">
          <MdVpnKey className="h-6 w-6 text-red-400" aria-hidden="true" />
        </span>
        {/* Input */}
        <input
          type="password"
          name="password"
          id="password"
          value={sec}
          onChange={handlePass}
          placeholder="Your password"
          className="form-input pl-11 w-full border-0 border-b-2 border-gray-300 bg-white bg-opacity-80 focus:border-gray-300 focus:ring-0"
          onFocus={() => setFocused(2)}
          onBlur={() => setFocused(null)}
        />
        {/* Border bottom effect */}
        <span
          className={`absolute bottom-0 left-0 h-0.5 bg-red-400 transition-all duration-300 ease-in transform ${
            focused === 2 ? "w-full" : "w-0"
          }`}
          aria-hidden="true"
        />
      </div>
      <div className="flex flex-col md:flex-row gap-3 justify-between mr-5 px-2 items-center">
        <button
          onClick={() => setShowModal(!show)}
          className="rounded-lg py-2 md:w-1/3 w-full transition ease-in-out active:scale-90 hover:scale-110 bg-gradient-to-r from-rose-600 via-rose-500 to bg-rose-300 shadow shadow-orange-500/70"
        >
          Cancel
        </button>
        <button
          onClick={() => withdraw()}
          disabled={!passed}
          className={`rounded-lg w-full md:w-1/3 py-2 ${
            passed
              ? "active:scale-90 transition ease-in-out active:animate-none animate-bounce bg-gradient-to-r from-indigo-600 via-indigo-500 to bg-fuchsia-500 shadow shadow-blue-500/70"
              : "bg-gray-500 cursor-not-allowed"
          }`}
        >
          Enter
        </button>
      </div>
    </div>
  );
};

export default Modal;
