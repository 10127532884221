import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoadingPage = ({ detail }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(time, 1000);
  }, [detail]);

  const time = () => {
    try {
      if (detail) {
        navigate("/dashboard");
      } else {
        navigate("/new-profile");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex w-full">
      <div className="flex p-5 flex-col m-auto md:w-1/4 gap-3">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-slate-700 h-10 w-10"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-700 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-700 rounded"></div>
            </div>
          </div>
        </div>
        <div className="animate-pulse font-sans font-bold text-3xl flex justify-center w-full p-5">
          LOADING ....
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
