import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Navigate, useNavigate } from "react-router-dom";
import { supabase } from "../client";
import { MdMarkEmailRead } from "react-icons/md";
import { HiKey, HiUser } from "react-icons/hi";

const SingUp = ({ users, signOut, fetchUser, userDetail, setUserDetail }) => {
  const navigate = useNavigate();
  const [focused, setFocused] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [matchPass, setMatchPass] = useState(false);
  const [passed, setPassed] = useState(false);

  useEffect(() => {
    if (!userDetail) return;
    navigate("/dashboard");
  }, [userDetail]);

  const checkLen = (value, len, fn) => {
    if (value.length >= len) {
      fn(true);
    } else {
      fn(false);
    }
  };

  useEffect(() => {
    checkLen(firstName, 3, setValidFirstName);
    checkLen(lastName, 3, setValidLastName);
    checkLen(pass1, 5, setValidPass);
  }, [firstName, lastName, pass1]);

  useEffect(() => {
    if (users && userDetail) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (pass2 === pass1) {
      setMatchPass(true);
    } else {
      setMatchPass(false);
    }
  }, [pass2, pass1]);

  useEffect(() => {
    if (matchPass && validFirstName && validLastName && validPass) {
      setPassed(true);
    } else {
      setPassed(false);
    }
  }, [matchPass, validFirstName, validLastName, validPass]);

  async function daftar() {
    if (!passed) return;
    const joined = `${users.email}${firstName}${lastName}${pass1}`;
    const qrId = `${users.id}${users.email}`;
    const hashCrypt = ethers.utils.solidityKeccak256(["string"], [joined]);
    const hashQr = ethers.utils.solidityKeccak256(["string"], [qrId]);
    const { data, error } = await supabase.from("profiles").insert([
      {
        id: users.id,
        email: users.email,
        first_name: firstName,
        last_name: lastName,
        challenge: hashCrypt,
        qrlink: hashQr,
      },
    ]);
    if (error) {
      console.error(error);
    }

    if (data) {
      try {
        const data = await fetchUser(users.id);
        setUserDetail(data);
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <>
      {!users ? (
        <Navigate to="/" />
      ) : (
        <div className="flex justify-center items-center flex-col px-3 py-10 bg-gradient-to-bl from-teal-600/90 via-teal-500/40 dark:from-slate-600/90 dark:via-slate-500/50 w-full">
          <div className=" backdrop-blur-lg backdrop-contrast-75 text-black md:w-1/2 bg-white/10 md:px-5 py-4 rounded-lg shadow shadow-yellow-400 dark:shadow-gray-400 flex flex-wrap justify-center items-center">
            {/* :INPUT 1 -> EMAIL */}
            <div className="my-5 mr-5 relative overflow-hidden">
              {/* Label */}
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              {/* Icon */}
              <span className="absolute inset-y-0 left-0 pl-3 inline-flex items-center pointer-events-none">
                <MdMarkEmailRead
                  className="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              </span>
              {/* Input */}
              <input
                type="email"
                name="email"
                id="email"
                disabled
                placeholder={users?.email}
                className="form-input pl-11 w-full border-0 border-b-2 cursor-not-allowed border-gray-300 bg-gray-600/90 bg-opacity-80 focus:border-gray-300 focus:ring-0"
                onFocus={() => setFocused(1)}
                onBlur={() => setFocused(null)}
              />
              {/* Border bottom effect */}
              <span
                className={`absolute bottom-0 left-0 h-0.5 bg-green-400 transition-all duration-300 ease-in transform ${
                  focused === 1 ? "w-full" : "w-0"
                }`}
                aria-hidden="true"
              />
            </div>
            {/* :INPUT 3 -> USERNAME */}
            <div className="my-5 mr-5 relative overflow-hidden">
              {/* Label */}
              <label htmlFor="first_name" className="sr-only">
                First Name
              </label>
              {/* Icon */}
              <span className="absolute inset-y-0 left-0 pl-3 inline-flex items-center pointer-events-none">
                <HiUser className="h-6 w-6 text-gray-800" aria-hidden="true" />
              </span>
              {/* Input */}
              <input
                type="text"
                name="first_name"
                id="first_name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                className="form-input pl-11 w-full border-0 border-b-2 border-gray-300 bg-white bg-opacity-80 focus:border-gray-300 focus:ring-0"
                onFocus={() => setFocused(3)}
                onBlur={() => setFocused(null)}
              />
              {/* Border bottom effect */}
              <span
                className={`absolute bottom-0 left-0 h-0.5 bg-gray-800 transition-all duration-300 ease-in transform ${
                  focused === 3 ? "w-full" : "w-0"
                }`}
                aria-hidden="true"
              />
            </div>
            <div className="my-5 mr-5 relative overflow-hidden">
              {/* Label */}
              <label htmlFor="last_name" className="sr-only">
                Last Name
              </label>
              {/* Icon */}
              <span className="absolute inset-y-0 left-0 pl-3 inline-flex items-center pointer-events-none">
                <HiUser
                  className="h-6 w-6 text-fuchsia-800"
                  aria-hidden="true"
                />
              </span>
              {/* Input */}
              <input
                type="text"
                name="last_name"
                id="last_name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                className="form-input pl-11 w-full border-0 border-b-2 border-gray-300 bg-white bg-opacity-80 focus:border-gray-300 focus:ring-0"
                onFocus={() => setFocused(4)}
                onBlur={() => setFocused(null)}
              />
              {/* Border bottom effect */}
              <span
                className={`absolute bottom-0 left-0 h-0.5 bg-fuchsia-800 transition-all duration-300 ease-in transform ${
                  focused === 4 ? "w-full" : "w-0"
                }`}
                aria-hidden="true"
              />
            </div>
            {/* :INPUT 2 -> PASSWORD */}
            <div className="my-5 mr-5 relative overflow-hidden">
              {/* Label */}
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              {/* Icon */}
              <span className="absolute inset-y-0 left-0 pl-3 inline-flex items-center pointer-events-none">
                <HiKey className="h-6 w-6 text-red-400" aria-hidden="true" />
              </span>
              {/* Input */}
              <input
                type="password"
                required
                name="password"
                id="password"
                placeholder="Your password"
                value={pass1}
                onChange={(e) => setPass1(e.target.value)}
                className="form-input pl-11 w-full border-0 border-b-2 border-gray-300 bg-white bg-opacity-80 focus:border-gray-300 focus:ring-0"
                onFocus={() => setFocused(2)}
                onBlur={() => setFocused(null)}
              />
              {/* Border bottom effect */}
              <span
                className={`absolute bottom-0 left-0 h-0.5 bg-red-400 transition-all duration-300 ease-in transform ${
                  focused === 2 ? "w-full" : "w-0"
                }`}
                aria-hidden="true"
              />
            </div>
            <div className="my-5 mr-5 relative overflow-hidden">
              {/* Label */}
              <label htmlFor="password" className="sr-only">
                confirm Password
              </label>
              {/* Icon */}
              <span className="absolute inset-y-0 left-0 pl-3 inline-flex items-center pointer-events-none">
                <HiKey className="h-6 w-6 text-sky-400" aria-hidden="true" />
              </span>
              {/* Input */}
              <input
                type="password"
                name="confirm password"
                id="confirm password"
                placeholder="Confirm password"
                value={pass2}
                onChange={(e) => setPass2(e.target.value)}
                className="form-input pl-11 w-full border-0 border-b-2 border-gray-300 bg-white bg-opacity-80 focus:border-gray-300 focus:ring-0"
                onFocus={() => setFocused(5)}
                onBlur={() => setFocused(null)}
              />
              {/* Border bottom effect */}
              <span
                className={`absolute bottom-0 left-0 h-0.5 bg-sky-400 transition-all duration-300 ease-in transform ${
                  focused === 5 ? "w-full" : "w-0"
                }`}
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="justify-evenly text-white gap-3 w-full font-sans font-medium py-5 mx-8 md:w-1/2 flex flex-wrap">
            <button
              onClick={() => signOut()}
              className="rounded w-full md:w-1/6 py-2 transition ease-in-out active:scale-90 hover:scale-110 bg-gradient-to-r from-rose-600 via-rose-500 to bg-rose-300 shadow shadow-orange-500/70"
            >
              Keluar
            </button>
            <button
              onClick={() => daftar()}
              className={`rounded w-full md:w-1/6 py-2 ${
                passed
                  ? "active:scale-90 transition ease-in-out active:animate-none animate-bounce bg-gradient-to-r from-indigo-600 via-indigo-500 to bg-fuchsia-500 shadow shadow-blue-500/70"
                  : "bg-gray-500 cursor-not-allowed"
              }`}
            >
              Daftar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SingUp;
