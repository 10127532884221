import React from "react";

const ModalWallet = ({ show, setShow, passed, handleSec, handleAuth }) => {
  return (
    <div
      className={`
${show ? "flex bounce-in-right" : "hidden"}
absolute text-xl font-sans font-semibold text-gray-200 top-1/3 w-full md:w-1/2 2xl:w-1/4 md:left-1/4 2xl:left-[50rem] left-1 px-12 py-5 bg-sky-900/10 dark:bg-white/20 backdrop-blur-lg rounded-lg flex-col`}
    >
      <div>
        <form className="flex flex-col gap-1">
          <label htmlFor="sandi">Masukan sandi</label>
          <input
            id="sandi"
            type="password"
            className="rounded px-2 font-san active:text-black focus:text-black"
            placeholder="password"
            onChange={handleSec}
          />
        </form>
      </div>
      <h1
        className={`invisible text-center text-md md:text-sm font-serif text-red-500`}
      >
        Password didn't match
      </h1>
      <div className="w-full text-base flex py-3 justify-between">
        <button
          onClick={() => setShow(!show)}
          className="rounded-lg bg-gradient-to-r text-white active:scale-95 ease-in-out from-red-600 to-rose-400/90 shadow duration-100 shadow-rose-300/90 hover:scale-105 px-2 py-1"
        >
          Cancel
        </button>
        <button
          onClick={() => handleAuth()}
          disabled={!passed}
          className={`rounded-lg bg-gradient-to-r text-white  px-2 py-1 ${
            passed
              ? "active:scale-95 ease-in-out from-teal-700 to-sky-500 shadow shadow-blue-400/90 duration-100 hover:scale-105"
              : "from-gray-600 via-gray-500 to-gray-300 cursor-not-allowed"
          }`}
        >
          Autentikasi
        </button>
      </div>
    </div>
  );
};

export default ModalWallet;
