import { useEffect, useState } from "react";

const ModalToken = ({
  inputBal,
  tokenAmount,
  handleClose,
  show,
  change,
  formater,
  selectedChain,
  chain,
  reqSign,
  func,
}) => {
  const [symbol, setSymbol] = useState(null);
  useEffect(() => {
    if (!selectedChain) {
      setSymbol(null);
    } else {
      const _symbol = chain.find((elem) => elem.tittle === selectedChain);
      setSymbol(_symbol.symbol);
    }
  }, [selectedChain]);

  return (
    <div
      className={`
${show ? "flex bounce-in-right" : "hidden"}
absolute text-xl font-sans font-semibold text-gray-200 top-1/3 gap-2 w-full md:w-1/2 2xl:w-1/4 md:left-1/4 2xl:left-[50rem] left-1 px-12 py-5 bg-sky-900/10 dark:bg-white/20 backdrop-blur-lg backdrop-contrast-50 rounded-lg flex-col`}
    >
      {!reqSign ? (
        <>
          <div className="flex flex-col gap-2 font-sans">
            <label htmlFor="nominalRp" className="font-semibold">
              Masukan nominal dalam rupiah
            </label>
            <input
              className="focus:text-black rounded placeholder:text-gray-200"
              type="number"
              min={0}
              max={100}
              step={1}
              value={inputBal}
              onChange={change}
              placeholder="Rp 1.000.000"
            />
          </div>
          <div className="flex py-4 px-6 justify-between text-base">
            <h1>Jumlah fiat :</h1>
            <h1>{formater.format(inputBal)}</h1>
          </div>
          <div className="flex py-4 px-6 justify-between text-base">
            <h1>Token yang didapat :</h1>
            <h1 className="truncate w-1/6">{tokenAmount}</h1>
            {symbol && symbol}
          </div>
          <div className="flex justify-between">
            <button
              onClick={() => handleClose()}
              className="rounded-lg bg-gradient-to-r text-base text-white active:scale-95 ease-in delay-50 from-amber-600 to-yellow-400/90 shadow-md duration-100 shadow-rose-300/90 hover:scale-105 px-2 py-1"
            >
              Close
            </button>
            <button
              onClick={() => func()}
              className="rounded-lg bg-gradient-to-r text-base text-white active:scale-95 ease-in delay-50 from-red-600 to-rose-400/90 shadow-md duration-100 shadow-rose-300/90 hover:scale-105 px-2 py-1"
            >
              Withdraw
            </button>
          </div>
        </>
      ) : (
        <div className="m-auto text-xl py-10 flex justify-center items-center flex-col">
          <h1>Buka crypto wallet mu</h1>
          <h1>Lalu klik sign</h1>
          <div className="w-10 p-5 rounded-lg mt-3 bg-white animate-spin" />
        </div>
      )}
    </div>
  );
};

export default ModalToken;
