import {
  MdAddLink,
  MdOutlineDocumentScanner,
  MdVideoSettings,
  MdOutlineCodeOff,
  MdOutlineScreenSearchDesktop,
  MdArrowForward,
} from "react-icons/md";
import { TbShare, TbTransferIn, TbBuildingBank } from "react-icons/tb";
import { TiDocument } from "react-icons/ti";

const ProfileHome = ({ userDetail }) => {
  return (
    <div className="w-full h-full text-white/80 font-sans flex gap-5 px-4 py-5 flex-col">
      <div id="judul" className="mx-auto w-full">
        <h1 className="text-center md:text-2xl text-xl font-sans font-bold">
          Hallo {userDetail?.first_name} {userDetail?.last_name}, Selamat Datang
          di Payment Gateway
        </h1>
      </div>
      <div id="jelajahi" className="flex flex-col mx-auto gap-2 w-full">
        <div className="flex justify-between">
          <h2 className="text-start md:text-lg text-md font-bold font-mulish">
            Jelajahi Contoh
          </h2>
          <p className="text-sky-500 block font-serif">Lihat Semua Produk</p>
        </div>
        <div className="md:flex-row flex mb-8 flex-col justify-between gap-4 w-full">
          {jelajahi.map((item, i) => (
            <div
              key={i}
              className={`flex shadow dark:shadow-gray-200 w-full ease-in-out duration-200 hover:scale-95 flex-col justify-start rounded-lg bg-gradient-to-tl from-indigo-700/60 dark:from-sky-700 gap-2 p-2`}
            >
              <div
                id="judul"
                className="flex text-lg items-start h-1/3 justify-between px-3 py-1"
              >
                <div className={`${item.iconColor} text-xl scale-125`}>
                  {item.icon}
                </div>
                <div>
                  <h3 className="text-lg font-semibold animate-pulse">
                    {item.judul}
                  </h3>
                </div>
              </div>
              <div className="flex w-full gap-2 px-1">
                <div className="w-3/4 h-full">
                  <p className="font-light font-sans texr-md">{item.isi}</p>
                </div>
                <div className="p-2 font-bold text-white m-auto rounded-full hover:border shadow flex justify-center">
                  <MdArrowForward />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        id="tindakan"
        className="flex flex-col gap-2 mb-8 md:flex-row w-full justify-around"
      >
        {tindakanCepat.map((item, i) => (
          <div
            key={i}
            className="md:w-1/3 w-full ease-in-out duration-200 hover:scale-95 flex bg-gradient-to-tl dark:shadow-white from-indigo-900/60 dark:from-sky-700 py-5 rounded-lg shadow"
          >
            <div className="flex flex-col items-start justify-center mr-3">
              <div className={`${item.iconColor} pr-6 py-6 rounded-r-full`} />
            </div>
            <div className="flex flex-col justify-between" id="isi-container">
              <h1 className="text-lg font-semibold animate-pulse">
                {item.judul}
              </h1>
              <p className="text-md font-sans">{item.isi}</p>
            </div>
            <div className="p-2 font-bold item-start text-white m-auto rounded-full hover:border shadow flex justify-center">
              <MdArrowForward />
            </div>
          </div>
        ))}
      </div>
      <div
        id="siap"
        className="flex flex-col mb-8 pb-2 md:flex-row w-full gap-5 justify-around"
      >
        {siap.map((item, i) => (
          <div
            key={i}
            className="w-full flex ease-in-out duration-200 hover:scale-95 flex-col pb-3 bg-gradient-to-tl dark:shadow-white from-indigo-800/70 dark:from-sky-700 rounded-lg shadow"
          >
            <div
              className={`full py-4 rounded-t bg-gradient-to-r ${item.iconColor} ${item.iconColor2} flex`}
            >
              <div className="m-auto text-2xl text-white">{item.icon}</div>
            </div>
            <div className="px-3 py-2">
              <h2 className="font-normal text-lg font-mulish animate-pulse">
                {item.judul}
              </h2>
              <p className="font-light font-sans text-md">{item.isi}</p>
            </div>
          </div>
        ))}
      </div>
      <div
        id="pelajari"
        className="flex flex-col md:flex-row w-full pb-5 gap-4 md:gap-8 justify-around"
      >
        {pelajari.map((item, i) => (
          <div
            key={i}
            className="w-full flex ease-in-out duration-200 hover:scale-95 flex-col bg-gradient-to-tl dark:shadow-white from-indigo-800/60 dark:from-sky-700 pb-5 rounded-lg shadow"
          >
            <div className="flex w-full hover:border-b-2 py-3 items-center justify-start">
              <div className={`text-2xl px-2 ${item.iconColor}`}>
                {item.icon}
              </div>
              <h1 className="w-full px-3 text-lg font-bold font-mulish">
                {item.judul}
              </h1>
            </div>
            <div className="px-3 flex flex-col h-full justify-center items-center">
              <p className="text-md font-light font-sans">{item.isi}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileHome;

const jelajahi = [
  {
    icon: <MdAddLink />,
    iconColor: "text-teal-700",
    judul: "Payment Link",
    isi: "Bagi link ke pelanggan Anda untuk menerima pembayaran dari berbagai metode pembayaran",
    att: "Recomended",
  },
  {
    icon: <TbShare />,
    iconColor: "text-blue-700",
    judul: "Third Party Integration",
    isi: "Hubungkan ke WooCommerce, Shopify, Wix dan masih banyak lagi",
    att: null,
  },
  {
    icon: <TbTransferIn />,
    iconColor: "text-purple-700",
    judul: "Disbursements",
    isi: "Kirim uang dengan cepat ke rekening bank dan e-wallet",
    att: null,
  },
];

const tindakanCepat = [
  {
    icon: "",
    iconColor: "bg-emerald-600",
    judul: "Buat Tautan Pembayaran",
    isi: "Menerima pembayaran menggunakan tautan pembayaran",
  },
  {
    icon: "",
    iconColor: "bg-rose-600",
    judul: "Aktifkan metode pembayaran lainnya",
    isi: "Terima pembayaran via OVO, QRIS, dan masih banyak lagi",
  },
];

const siap = [
  {
    icon: <TbBuildingBank />,
    iconColor: "from-sky-700",
    iconColor2: "to-sky-500",
    judul: "Tambahkan Rekening Bank Anda",
    isi: "Tambahkan rekening untuk mengirimkan/menarik dana ke rekening bank anda",
  },
  {
    icon: <MdOutlineDocumentScanner />,
    judul: "Tinjau Invoice",
    iconColor: "from-teal-700",
    iconColor2: "to-teal-500",
    isi: "Tinjau Bagaimana Invoice akan terlihat oleh pelanggan anda",
  },
  {
    icon: <MdOutlineCodeOff />,
    judul: "Atur API Key",
    iconColor: "from-amber-700",
    iconColor2: "to-amber-500",
    isi: "Lihat dan atur API key anda",
  },
];

const pelajari = [
  {
    icon: <TiDocument />,
    iconColor: "text-teal-700",
    judul: "Panduan",
    isi: "Butuh Bantuan? Panduan praktis kami akan menjawab pertanyaan anda",
  },
  {
    icon: <MdVideoSettings />,
    iconColor: "text-rose-700",
    judul: "Video Tutorial",
    isi: "Pelajari lebih mudah dengan mempelajari tutorial video Youtube kami",
  },
  {
    icon: <MdOutlineScreenSearchDesktop />,
    iconColor: "text-indigo-500",
    judul: "Baca Docs",
    isi: "Membiasakan diri anda dengan produk kami dengan membaca dokumentasi yang telah kami sediakan",
  },
];
