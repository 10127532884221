import { useEffect, useState } from "react";
import Modal from "../components/Modal";

import { utils } from "ethers/lib/ethers";
import { supabase } from "../client";

const Kirim = ({ detail, formater, checkUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [nominal, setNominal] = useState("");
  const [sec, setSec] = useState("");
  const [passed, setPassed] = useState(false);

  useEffect(() => {
    if (showModal) return;
    setNominal("");
    setSec("");
    setPassed(false);
  }, [showModal]);

  useEffect(() => {
    const len = sec.length;
    if (len === 0) return;
    const hash = hashCrypt(sec);
    if (hash === detail.challenge) {
      setPassed(true);
    } else {
      setPassed(false);
    }
  }, [sec]);

  const hashCrypt = (props) => {
    const keccak = utils.solidityKeccak256(
      ["string"],
      [`${detail.email}${detail.first_name}${detail.last_name}${props}`]
    );
    return keccak;
  };

  const withdraw = async () => {
    if (nominal.length === 0) return;
    const jumlah = detail.balance - nominal;

    const { data, error } = await supabase
      .from("profiles")
      .update({ balance: jumlah })
      .match({ id: detail.id });

    if (error) {
      console.error(error);
    }

    if (data) {
      await checkUser();
      setShowModal(false);
    }
  };

  return (
    <div className="flex flex-col text-white font-sans px-4 w-full h-full">
      <div className="animate-textShimmer font-sans py-3 text-4xl font-bold bg-clip-text text-transparent bg-[linear-gradient(110deg,#e2e8f0,45%,#1e293b,55%,#e2e8f0)] bg-[length:250%_100%]">
        Kirim Pembayaran
      </div>
      <div className="text-lg font-mulish pb-5">
        Jumlah Saldo : {formater.format(detail?.balance)}{" "}
      </div>
      <div
        id="crypto"
        className="flex flex-col w-full rounded-xl gap-3 py-4 px-5"
      ></div>
      <div
        id="fiat"
        className="w-full flex justify-center lg:justify-evenly flex-wrap py-4 px-5 gap-5"
      >
        {dompetDigital.map((item, i) => (
          <div
            key={i}
            className="w-full md:w-1/4 lg:w-1/5 shadow bg-gray-50/10 shadow-slate-700 rounded box-border p-5 flex md:h-40 flex-col justify-between"
          >
            <div className="w-full flex justify-center overflow-hidden py-3">
              <img
                src={item.image}
                className={`md:ml-4 ${item.className}`}
                alt="jaringan crypto"
              />
            </div>
            <div className="mt-5 flex flex-grow items-center">
              <button
                onClick={() => setShowModal(!showModal)}
                className="w-full px-2 py-1 shadow shadow-slate-700/30 rounded-lg bg-gradient-to-r active:scale-95 hover:scale-105 ease-in-out duration-150 font-semibold from-fuchsia-800/90 via-violet-500 to-pink-400"
              >
                Tarik dana
              </button>
            </div>
          </div>
        ))}
      </div>
      <Modal
        setShowModal={setShowModal}
        show={showModal}
        nominal={nominal}
        setNominal={setNominal}
        balance={detail.balance}
        setSec={setSec}
        sec={sec}
        passed={passed}
        withdraw={withdraw}
      />
    </div>
  );
};

export default Kirim;

const dompetDigital = [
  {
    tittle: "gopay",
    image: "/images/gopay.png",
    className: "md:w-1/2",
  },
  {
    tittle: "dana",
    image: "/images/dana.png",
    className: "md:w-1/2",
  },
  {
    tittle: "link",
    image: "/images/link.png",
    className: "px-12 md:px-0 h-full",
  },
  {
    tittle: "ovo",
    image: "/images/ovo.png",
    className: "h-full",
  },
  {
    tittle: "bca",
    image: "/images/bca.png",
    className: "md:w-1/2",
  },
  {
    tittle: "bri",
    image: "/images/bri.png",
    className: "h-full px-12 md:px-0",
  },
  {
    tittle: "mandiri",
    image: "/images/mandiri.png",
    className: "md:w-1/2",
  },
  {
    tittle: "muamalat",
    image: "/images/muamalat.png",
    className: "md:w-1/2",
  },
];
