import { Outlet, NavLink, Navigate } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import {
  TbCash,
  TbReportSearch,
  TbZoomMoney,
  TbLink,
  TbUsers,
  TbBuildingStore,
  TbSearch,
  TbCurrencyDogecoin,
} from "react-icons/tb";
import { AiOutlineTransaction } from "react-icons/ai";
import { MdQrCode, MdSendToMobile } from "react-icons/md";
import { FaHouseUser } from "react-icons/fa";

const Dashboard = ({ detail }) => {
  return (
    <>
      {!detail ? (
        <Navigate to="/" />
      ) : (
        <div className="flex flex-row w-full h-full">
          <div className="flex flex-col justify-between text-yellow-50/80 items-start h-full w-1/6 py-5 px-2 shadow-xl bg-gradient-to-bl from-green-900/70 via-teal-700/70 to-yellow-700/20 shadow-red-500/30 dark:from-gray-900/80 dark:via-gray-800/20 dark:shadow-slate-600/80">
            <div
              id="menu"
              className="flex xl:px-4 px-1 mb-2 flex-col w-full gap-1"
            >
              <h1 className="font-mulish hidden xl:flex text-md lg:text-lg font-semibold">
                Menu Utama
              </h1>
              <div className="flex flex-col gap-1">
                {mainMenu.map((menu, i) => (
                  <NavLink
                    to={menu.path}
                    key={i}
                    end
                    className={({ isActive }) =>
                      `flex gap-3 p-1 justify-center md:justify-start items-center w-full h-full ${
                        isActive && "font-semibold rounded border-2 scale-105"
                      }`
                    }
                  >
                    <div className="text-3xl flex justify-center xl:text-xl">
                      {menu.icon}
                    </div>
                    <span className="hidden md:flex text-sm 2xl:text-lg">
                      {menu.title}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>
            <div
              id="partner"
              className="flex px-1 md:px-4 flex-col w-full gap-1"
            >
              <h1 className="font-mulish hidden xl:flex text-md lg:text-lg font-semibold">
                App & Partner
              </h1>
              <div className="flex flex-col justify-center">
                {appPartner.map((menu, i) => (
                  <NavLink
                    to={menu.path}
                    key={i}
                    className={({ isActive }) =>
                      `flex xl:text-xl gap-3 p-1 justify-center md:justify-start items-center w-full h-full ${
                        isActive && "font-semibold rounded border-2 scale-105"
                      }`
                    }
                  >
                    <div className="text-3xl xl:text-xl">{menu.icon}</div>
                    <span className="hidden md:flex text-sm 2xl:text-lg">
                      {menu.title}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full h-full p-3 bg-gradient-to-br overflow-y-scroll from-teal-700/50 via-teal-200/20 dark:from-gray-800/90 dark:via-gray-900/20">
            {!detail ? <Navigate to="/loading" /> : <Outlet />}
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;

const mainMenu = [
  {
    icon: <IoHome />,
    title: "Beranda",
    path: "/dashboard",
  },
  {
    icon: <TbCash />,
    title: "Saldo",
    path: "/dashboard/balance",
  },
  {
    icon: <MdSendToMobile />,
    title: "Kirim Pembayaran",
    path: "/dashboard/transfer",
  },
  {
    icon: <TbCurrencyDogecoin />,
    title: "Dompet Crypto",
    path: "/dashboard/crypto",
  },
  {
    icon: <AiOutlineTransaction />,
    title: "Transaksi",
    path: "/dashboard/transaction",
  },
  {
    icon: <TbReportSearch />,
    title: "Laporan",
    path: "/dashboard/report",
  },
  {
    icon: <TbZoomMoney />,
    title: "Terima Pembayaran",
    path: "/dashboard/accept",
  },
  {
    icon: <TbLink />,
    title: "Tautan Pembayaran",
    path: "/dashboard/link",
  },
  {
    icon: <MdQrCode />,
    title: "QR Codes",
    path: "/dashboard/qr",
  },
  {
    icon: <TbUsers />,
    title: "Pelanggan",
    path: "/dashboard/costumer",
  },
];

const appPartner = [
  {
    icon: <FaHouseUser />,
    title: "Platform",
    path: "/dashboard/platform",
  },
  {
    icon: <TbBuildingStore />,
    title: "Toko",
    path: "/dashboard/store",
  },
  {
    icon: <TbSearch />,
    title: "Lihat Semua",
    path: "/dashboard/all",
  },
];
