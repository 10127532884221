import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { MdVpnKey } from "react-icons/md";
import Resizer from "react-image-file-resizer";
import { ethers } from "ethers";
import { supabase } from "../client";
import { useNavigate } from "react-router-dom";

const Profile = ({ detail, checkUser }) => {
  const navigate = useNavigate();
  const [focused, setFocused] = useState();
  const [image, setImage] = useState(detail?.avatar_url);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [notMatch, setNotMatch] = useState(false);
  const [activateBtn, setActivateBtn] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [hash, setHash] = useState(null);

  useEffect(() => {
    console.log(notMatch);
  }, [notMatch]);

  useEffect(() => {
    if (pass1.length < 4) return;
    if (pass1 === pass2) {
      setNotMatch(false);
      setActivateBtn(true);
    } else {
      setNotMatch(true);
      setActivateBtn(false);
    }
  }, [pass1, pass2]);

  const handlePass = (fun) => (e) => {
    const target = e.target.value;
    fun(target);
  };

  const changeImage = async (e) => {
    const uploaded = e.target.files[0];
    if (uploaded) {
      setImage(uploaded);
    }
  };

  const updateProfile = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .update({
        avatar_url: image === detail.avatar_url ? detail.avatar_url : avatarUrl,
        challenge: hash ? hash : detail.challenge,
      })
      .match({ id: detail.id });

    if (error) {
      console.error(error);
    }

    if (data) {
      await checkUser();
      navigate("/loading");
    }
  };

  const uploadImage = async () => {
    if (image === detail.avatar_url) return;
    const imageComp = await resizeFile(image);
    const { data, error } = await supabase.storage
      .from("avatars")
      .upload(`${detail.first_name}_${Date.now()}`, imageComp, {
        upsert: true,
      });
    if (error) {
      console.error(error);
    }

    if (data) {
      console.log("upload", data);
      setAvatarUrl(`${process.env.REACT_APP_SUPABASE_IMAGE_BASE}${data.Key}`);
    }
  };

  const simpanPass = () => {
    if (pass1.length < 4) return;
    if (notMatch) return;
    const hashCrypt = ethers.utils.solidityKeccak256(
      ["string"],
      [`${detail.email}${detail.first_name}${detail.last_name}${pass1}`]
    );
    setHash(hashCrypt);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "WEBP",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  return (
    <>
      {!detail ? (
        <Navigate to="/loading" />
      ) : (
        <div className="w-full flex">
          <div className="m-auto md:w-1/2 p-5 gap-3 bg-teal-100/20 backdrop-blur-lg dark:bg-inherit flex rounded-md flex-col shadow shadow-slate-300/50">
            <div
              id="detail"
              className="flex font-sans font-semibold flex-col justify-start items-start pt-4 md:w-3/4 gap-3"
            >
              <h1>{detail.email}</h1>
              <div className="flex gap-1 justify-start">
                <h1>{detail.first_name}</h1>
                <h1>{detail.last_name}</h1>
              </div>
            </div>
            <h1 className="font-sans w-full text-center font-semibold">
              Ubah Password Dan Ganti Profile Picture
            </h1>
            <div id="avatar and pass" className="flex w-full justify-between">
              <div
                id="avatar"
                className="w-1/2 box-border rounded-lg items-center p-3 flex flex-col"
              >
                <label
                  htmlFor="avatar_img"
                  className="cursor-pointer inline-flex justify-center h-40 w-40"
                >
                  <input
                    id="avatar_img"
                    type="file"
                    onChange={changeImage}
                    className="hidden border"
                  />
                  <figure className="relative object-contain justify-center overflow-hidden h-36 w-36 shadow shadow-slate-600 ring-1 p-1 ring-violet-500 rounded-full inline-flex ">
                    <img
                      src={
                        image === detail.avatar_url
                          ? image
                          : URL.createObjectURL(image)
                      }
                      className="w-full h-full rounded-full"
                    />
                    <figcaption className="absolute flex top-0 w-full h-full overflow-hidden p-10 rounded-full opacity-0 bg-black/30 ease-in-out duration-150 hover:opacity-100">
                      <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" />
                    </figcaption>
                  </figure>
                </label>
                <h1
                  className={`invisible text-center text-md md:text-sm font-serif text-red-500`}
                >
                  Password didn't match
                </h1>
                <button
                  disabled={image === detail.avatar_url}
                  onClick={() => uploadImage()}
                  className={`w-full py-2 bg-gradient-to-r rounded-lg ${
                    image !== detail.avatar_url
                      ? "ease-in-out duration-200 hover:scale-110 active:scale-90 from-fuchsia-800/90 via-indigo-500/90 to-sky-300"
                      : "from-gray-600 via-gray-500 to-gray-300 cursor-not-allowed"
                  }`}
                >
                  Upload
                </button>
              </div>
              <div className="flex w-full flex-col">
                <div className="my-5 mr-5 relative overflow-hidden">
                  {/* Label */}
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  {/* Icon */}
                  <span className="absolute inset-y-0 left-0 pl-3 inline-flex items-center pointer-events-none">
                    <MdVpnKey
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </span>
                  {/* Input */}
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={pass1}
                    onChange={handlePass(setPass1)}
                    placeholder="Ganti password"
                    className="form-input pl-11 w-full border-0 border-b-2 border-gray-300 bg-white bg-opacity-80 focus:border-gray-300 focus:ring-0"
                    onFocus={() => setFocused(2)}
                    onBlur={() => setFocused(null)}
                  />
                  {/* Border bottom effect */}
                  <span
                    className={`absolute bottom-0 left-0 h-0.5 bg-red-400 transition-all duration-300 ease-in transform ${
                      focused === 2 ? "w-full" : "w-0"
                    }`}
                    aria-hidden="true"
                  />
                </div>
                <div className="my-5 mr-5 relative overflow-hidden">
                  {/* Label */}
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  {/* Icon */}
                  <span className="absolute inset-y-0 left-0 pl-3 inline-flex items-center pointer-events-none">
                    <MdVpnKey
                      className="h-6 w-6 text-blue-400"
                      aria-hidden="true"
                    />
                  </span>
                  {/* Input */}
                  <input
                    type="password"
                    name="password"
                    id="password2"
                    value={pass2}
                    onChange={handlePass(setPass2)}
                    placeholder="Confirm password"
                    className="form-input pl-11 w-full border-0 border-b-2 border-gray-300 bg-white bg-opacity-80 focus:border-gray-300 focus:ring-0"
                    onFocus={() => setFocused(1)}
                    onBlur={() => setFocused(null)}
                  />
                  {/* Border bottom effect */}
                  <span
                    className={`absolute bottom-0 left-0 h-0.5 bg-sky-400 transition-all duration-300 ease-in transform ${
                      focused === 1 ? "w-full" : "w-0"
                    }`}
                    aria-hidden="true"
                  />
                </div>
                <h1
                  className={`${
                    notMatch ? "visible" : "invisible"
                  } text-center text-xl font-serif text-red-500`}
                >
                  Password didn't match
                </h1>
                <div className="mr-5">
                  <button
                    onClick={() => simpanPass()}
                    className={`w-full rounded-lg py-2 ${
                      activateBtn
                        ? "ease-in-out duration-200 hover:scale-110 active:scale-90  bg-gradient-to-r from-pink-800/90 via-fuchsia-500/90 to-sky-300"
                        : "bg-gray-500 cursor-not-allowed"
                    }`}
                  >
                    Ganti Password
                  </button>
                </div>
              </div>
            </div>
            <h1
              className={`${
                avatarUrl ? "visible" : "invisible"
              } text-center text-sm font-serif text-teal-500`}
            >
              Profile Foto berhasil di upload
            </h1>
            <h1
              className={`${
                hash ? "visible" : "invisible"
              } text-center text-sm font-serif text-sky-500`}
            >
              Password berhasil diganti
            </h1>
            <button
              onClick={() => updateProfile()}
              className={`w-full rounded-lg py-2 ${
                avatarUrl || hash
                  ? "ease-in-out duration-200 hover:scale-110 active:scale-90  bg-gradient-to-r from-indigo-800/90 via-blue-500/90 to-sky-300"
                  : "bg-gray-500 cursor-not-allowed"
              }`}
            >
              Update Profile
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
