import "./index.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { supabase } from "./client";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import ProfileHome from "./pages/ProfileHome";
import Balance from "./pages/Balance";
import Transaksi from "./pages/Transaksi";
import Laporan from "./pages/Laporan";
import Pembayaran from "./pages/Pembayaran";
import Tautan from "./pages/Tautan";
import QR from "./pages/QR";
import Kirim from "./pages/Kirim";
import Pelanggan from "./pages/Pelanggan";
import SingUp from "./pages/SingUp";
import Crypto from "./pages/Crypto";
import Layout from "./components/Layout";
import LoadingPage from "./pages/LoadingPage";
import Profile from "./pages/Profile";

const formatCurrency = new Intl.NumberFormat("id", {
  style: "currency",
  currency: "IDR",
});

const walletConnect = new WalletConnectProvider({
  rpc: {
    1: "https://eth-rpc.gateway.pokt.network",
    56: "https://rpc.ankr.com/bsc",
    137: "https://rpc-mainnet.matic.quiknode.pro",
    80001: "https://rpc.ankr.com/polygon_mumbai",
    43114: "https://rpc.ankr.com/avalanche",
    250: "https://rpc3.fantom.network",
    8217: "https://klaytn02.fandom.finance",
    42161: "https://arb1.arbitrum.io/rpc",
  },
});

function App() {
  const [users, setUser] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [wallet, setWallet] = useState();
  const [addr, setAddr] = useState(null);
  const [provider, setProvider] = useState();
  const [instance, setInstance] = useState();

  useEffect(() => {
    checkUser();
    window.addEventListener("hashchange", function () {
      checkUser();
    });
    setInstance(walletConnect);

    const walletStorage = window.localStorage.getItem("walletconnect");
    if (walletStorage) {
      window.localStorage.removeItem("walletconnect");
    }

    return () => {
      disconnectWallet();
    };
  }, []);

  async function disconnectWallet() {
    try {
      await instance.disconnect();
      setInstance(undefined);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchUser(userId) {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .match({ id: userId })
        .maybeSingle();

      if (error) {
        console.log(error);
      }
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async function checkUser() {
    const user = supabase.auth.user();
    if (!user) return;
    setUser(user);
    const data = await fetchUser(user.id);
    if (data) setUserDetail(data);
  }

  const signGoogle = async () => {
    await supabase.auth.signIn({ provider: "google" });
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
    setUserDetail(null);
  };

  const signMobile = async () => {
    try {
      await instance.enable();
      const _provider = new ethers.providers.Web3Provider(walletConnect);
      setProvider(_provider);
      const address = await _provider.listAccounts();
      setAddr(address[0]);
    } catch (err) {
      console.error(err);
    }
  };

  const insertWallet = async (addr) => {
    const { data, error } = await supabase
      .from("wallet")
      .insert({ wallet_address: addr });
    if (error) {
      console.error(error);
    }

    if (data) {
      setWallet(data);
    }
  };

  const fetchWallet = async (addr) => {
    try {
      const { data, error } = await supabase
        .from("wallet")
        .select()
        .match({ wallet_address: addr })
        .maybeSingle();
      if (error) {
        console.log(error);
      }

      if (data) {
        setWallet(data);
      } else {
        await insertWallet(addr);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!addr) return;
    fetchWallet(addr);
  }, [addr]);

  const logOutWallet = async () => {
    await disconnectWallet();
    setProvider(undefined);
    setWallet(undefined);
    setAddr(null);
  };

  return (
    <BrowserRouter>
      <Layout userDetail={userDetail} users={users} signOut={signOut}>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                sign={signGoogle}
                users={users}
                fetchUser={fetchUser}
                detail={userDetail}
              />
            }
          ></Route>
          <Route path="dashboard" element={<Dashboard detail={userDetail} />}>
            <Route index element={<ProfileHome userDetail={userDetail} />} />
            <Route
              path="balance"
              element={
                <Balance detail={userDetail} formater={formatCurrency} />
              }
            />
            <Route path="transaction" element={<Transaksi />} />
            <Route path="report" element={<Laporan />} />
            <Route path="accept" element={<Pembayaran />} />
            <Route path="link" element={<Tautan />} />
            <Route path="qr" element={<QR />} />
            <Route
              path="transfer"
              element={
                <Kirim
                  detail={userDetail}
                  formater={formatCurrency}
                  checkUser={checkUser}
                />
              }
            />
            <Route
              path="crypto"
              element={
                <Crypto
                  detail={userDetail}
                  formater={formatCurrency}
                  auth={signMobile}
                  wallet={wallet}
                  logOutWallet={logOutWallet}
                  setWallet={setWallet}
                  setDetail={setUserDetail}
                  provider={provider}
                />
              }
            />
            <Route path="costumer" element={<Pelanggan />} />
            <Route path="platform" element={<Pelanggan />} />
            <Route path="store" element={<Pelanggan />} />
            <Route path="all" element={<Pelanggan />} />
          </Route>
          <Route
            path="new-profile"
            element={
              <SingUp
                users={users}
                signOut={signOut}
                userDetail={userDetail}
                setUserDetail={setUserDetail}
                fetchUser={fetchUser}
              />
            }
          />
          <Route path="loading" element={<LoadingPage detail={userDetail} />} />
          <Route
            path="profile"
            element={<Profile detail={userDetail} checkUser={checkUser} />}
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
