import { Navigate } from "react-router-dom";

const Balance = ({ detail, formater }) => {
  return (
    <>
      {!detail ? (
        <Navigate to="loading" />
      ) : (
        <div className="flex h-full w-full justify-center">
          <div className="my-auto w-full px-20">
            <div className="flex text-2xl shadow py-4 rounded-lg shadow-slate-500 font-mono font-semibold justify-evenly">
              <h1>Total Saldo :</h1>
              <h1>{formater.format(detail.balance)}</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Balance;
