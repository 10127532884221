import { useNavigate, Link } from "react-router-dom";

const UserMini = ({ name, avatar, userClick, setUserClick, signOut }) => {
  const navigate = useNavigate();

  const handleLogOut = async () => {
    await signOut();
    navigate("/");
  };

  return (
    <div
      onClick={() => setUserClick(!userClick)}
      className="flex relative dark:text-white text-gray-500 h-14 mr-4 cursor-pointer ml-6 gap-2 items-center"
    >
      <div className="dark:text-white text-gray-800/90 font-semibold font-sans flex flex-col h-full justify-end">
        <h1 className="text-base underline">{name}</h1>
        <h1 className="text-base underline truncate w-10">
          PT. Sultan Java Jewerly
        </h1>
      </div>
      <div className="rounded-full inline-flex object-cover overflow-hidden w-14 h-14 ring-1 p-1 ring-sky-400">
        <img
          src={avatar}
          className="object-cover w-full inline-block rounded-full"
        />
      </div>
      <div
        className={`absolute py-2 justify-between top-16 w-screen -right-1/3 md:right-0 z-10 backdrop-filter backdrop-blur-md bg-gray-300/10 md:w-40 md:h-52 rounded-md ${
          userClick ? "flex" : "hidden"
        } flex-col`}
      >
        <Link to="/profile" className="flex gap-2">
          <h3 className="hover:bg-gray-500 text-center px-1 py-3 rounded w-full font-sans text-lg font-semibold">
            Profile
          </h3>
        </Link>
        <div className="flex justify-center w-full py-2 px-5">
          <button
            onClick={() => handleLogOut()}
            className="w-full py-1 text-white bg-gradient-to-r rounded-lg active:scale-95 hover:scale-110 ease-in-out duration-100 from-rose-700 via-rose-500 to-rose-400"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserMini;
