import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Home = ({ sign, users, detail }) => {
  const navigate = useNavigate();

  // async function checkDetail() {
  //   const data = await fetchUser(users.id);
  //   setUserDetail(data);
  // }

  useEffect(() => {
    if (!users) return;
    if (detail) {
      navigate("/dashboard");
    } else {
      navigate("/loading");
    }
  }, [users]);

  return (
    <div className="relative w-full flex flex-col-reverse md:flex-row items-center overflow-hidden">
      {/* :HERO IMAGE */}
      <div
        className="absolute w-full h-full bg-gradient-to-t from-yellow-300 to-teal-500 dark:from-blue-900 dark:via-purple-900/30 dark:to-purple-700/5"
        style={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 75%)" }}
      >
        <img
          src="/logo512.png"
          alt=""
          className="absolute w-full h-full md:left-80 object-contain object-top opacity-30"
        />
      </div>

      {/* :PRODUCT */}
      <div className="hidden sm:block relative w-full md:w-1/2 h-1/2 md:h-full">
        <div className="relative top-52 flex flex-col mx-auto justify-center gap-8 sm:w-40 md:w-60 xl:w-64 sm:h-56 md:h-72 xl:h-80 backdrop-blur-sm rounded-lg bg-white/30">
          <div
            onClick={() => sign()}
            className="group [transform:translateZ(0)] active:scale-90 select-none px-6 py-3 dark:border-sky-500 border-red-500 border-2 mx-auto cursor-pointer rounded-lg bg-transparent overflow-hidden relative before:absolute before:bg-amber-600 before:dark:bg-sky-600 before:bottom-0 before:left-0 before:h-full before:w-full before:-translate-x-full hover:before:translate-x-0 before:transition before:ease-in-out before:duration-500"
          >
            <span className="relative z-0 flex items-center gap-2 text-lg font-mulish group-hover:text-gray-200 transition ease-in-out duration-500">
              <FcGoogle />
              Masuk
            </span>
          </div>
          <div className=" w-full px-10">
            <div className="text-white border-t-2" />
          </div>
          <div
            onClick={() => sign()}
            className="group [transform:translateZ(0)] px-6 py-3 active:scale-90 select-none dark:border-sky-500 border-red-500 border-2 mx-auto cursor-pointer rounded-xl bg-transparent overflow-hidden relative before:absolute before:bg-amber-600 before:dark:bg-sky-600 before:bottom-0 before:left-0 before:h-full before:w-full before:-translate-x-full hover:before:translate-x-0 before:transition before:ease-in-out before:duration-500"
          >
            <span className="relative z-0 flex items-center gap-2 text-lg font-mulish group-hover:text-gray-200 transition ease-in-out duration-500">
              <FcGoogle />
              Daftar
            </span>
          </div>
        </div>
      </div>

      {/* :HERO MAIN */}
      <div className="relative pt-20 pb-10 md:py-40 px-3 w-full md:w-1/2 h-full sm:h-1/2 md:h-full flex flex-col justify-center items-center md:items-start text-center md:text-left text-white">
        {/* ::Small Title */}
        <h2 className="text-xl sm:text-3xl mb-3 font-mulish">
          Payment Gateway Untuk
        </h2>

        {/* ::Big Title */}
        <div className="text-gray-500 text-4xl md:text-start font-bold uppercase flex flex-col md:justify-start">
          <div className="overflow-hidden h-14">
            <div className="animate-slide">
              <span className="inline-block text-white italic py-1 px-3 mb-11  bg-gradient-to-l from-teal-600 to-teal-400 dark:from-gray-900 dark:to-gray-700/60">
                MASA DEPAN
              </span>
            </div>
            <div>
              <span className="inline-block text-white italic py-1 px-3 mb-11 bg-gradient-to-l from-sky-600 to-sky-400 dark:from-indigo-800 dark:to-indigo-500">
                UMKM
              </span>
            </div>
            <div>
              <span className="inline-block text-white italic py-1 px-3 mb-11 bg-gradient-to-l from-amber-600 to-amber-400 dark:from-purple-800 dark:to-purple-500">
                SEMUA KALANGAN
              </span>
            </div>
          </div>
        </div>

        {/* ::Text */}
        <p className="max-w-lg py-5 font-medium leading-relaxed">
          Contoh adalah payment gateway yang membantu bisnis Anda berkembang.
          Kami membantu bisnis menerima berbagai metode pembayaran dan mengirim
          dana ke berbagai tujuan, sehingga Anda bisa fokus pada pertumbuhan
          bisnis.
        </p>
        <div
          onClick={() => sign()}
          className="group md:hidden [transform:translateZ(0)] active:scale-90 select-none px-8 py-2 dark:border-sky-500 border-red-500 border-2 mx-auto cursor-pointer rounded-xl bg-transparent overflow-hidden relative before:absolute before:bg-amber-600 before:dark:bg-sky-600 before:bottom-0 before:left-0 before:h-full before:w-full before:-translate-x-full hover:before:translate-x-0 before:transition before:ease-in-out before:duration-500"
        >
          <span className="relative z-0 flex items-center gap-2 text-lg font-mulish group-hover:text-gray-200 transition ease-in-out duration-500">
            <FcGoogle />
            Masuk
          </span>
        </div>
        {/* ::Download App */}
        <div className="py-5 md:flex flex-col hidden sm:flex-row items-center">
          {/* Apple Store */}
          <a href="#link" className="py-2 sm:pr-5">
            <img src="/images/google.png" alt="" className="w-40" />
          </a>
          {/* Google Store */}
          <a href="#link" className="py-3 sm:pl-6">
            <img src="/images/appstore.png" alt="" className="w-36" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
